@import 'mixins';

.home-slider {
  position: relative;
  isolation: isolate;
  background: url('../images/home-slider-bg.jpg') no-repeat center center;
  background-size: cover;
  box-sizing: border-box;

  .swiper {
    padding: 70px 0 50px !important;
    max-width: 1305px;

    @include desktop {
      padding: 94px 0 130px !important;
      box-sizing: border-box;
    }
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 139px;
    height: 257px;
    opacity: 0.5;

    @include desktop {
      width: 309px;
      height: 577px;
    }
  }

  &:before {
    left: 0;
    bottom: 0;
    background: url('../images/home-slider-star-left.png') no-repeat center center;
    background-size: cover;

    @include desktop {
      bottom: 50px;
    }
  }

  &:after {
    top: 0;
    right: 0;
    background: url('../images/home-slider-star-left.png') no-repeat center center;
    background-size: cover;
    transform: scale(-1, 1);

    @include desktop {
      top: 50px;
    }
  }

  ul {
    li {
      box-sizing: border-box;
      padding: 10px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include desktop {
        padding: 0;
      }

      &.swiper-slide-active {
        .slider-text {
          @include desktop {
            opacity: 1;
          }
        }
      }

      .slider-text {
        margin-bottom: 40px;
        color: #fff;
        font-size: 26px;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        transition: opacity 0.8s ease-in-out;

        @include desktop {
          z-index: 1;
          opacity: 0;
          left: 0;
          right: 0;
          text-align: center;
          position: absolute;
          bottom: 9%;
          font-size: min(1.3vw, 26px);
          margin-bottom: 0;
          padding: 0 20px;
          letter-spacing: 1.1px;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              display: block;
              inset: -7px -12px;
              background: rgba(0, 0, 0, 0.6);
              filter: blur(15px);
              z-index: -1;
            }
          }
        }
      }

      picture {
        line-height: 0;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: calc(100vw - 20px);
        max-height: 500px;
        filter: grayscale(1) blur(4px) contrast(1.2);
        transition: all 0.7s ease-in-out;

        @include desktop {
          height: auto;
          max-height: 100%;
          opacity: 0.85;
          transform: scale(0.68);
        }
      }

      &.swiper-slide-next {
        @include desktop {
          transform: translateX(-50%);
        }
      }

      &.swiper-slide-prev {
        @include desktop {
          transform: translateX(50%);
        }
      }

      &.swiper-slide-active {
        z-index: 1;

        img {
          filter: grayscale(0) blur(0) contrast(1);
        }

        @include desktop {
          transform: translateX(0);

          img {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
    }
  }

  .home-slider-bullets {
    display: flex;
    margin-top: 40px;
    justify-content: center;

    @include desktop {
      margin-top: 0;
      position: absolute;
      bottom: 80px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border: 0;
    padding: 0;
    border-radius: 50%;
    background-color: #999;
    cursor: pointer;

    @include desktop {
      width: 18px;
      height: 18px;
      background-color: #e5e5e5;
    }

    & + span {
      margin-left: 20px;
    }

    &.swiper-pagination-bullet-active {
      background-color: var(--orange);
      cursor: default;
    }
  }

  .cookies-consent-container {
    position: absolute;
    z-index: 1;
    left: 10px;
    right: 10px;
    bottom: 0;
  }
}

.home-solutions-services {
  padding: 40px 0 calc(6vw * 3);
  background: linear-gradient(180deg, #da5c08 0%, #fff 500px);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    display: block;
    left: 0;
    right: 0;
    background: url('../images/waves-solutions-footer.png') bottom / contain no-repeat;
    background-size: contain;
    pointer-events: none;
    z-index: 1;
    bottom: 3vw;

    @media (min-width: 801px) and (max-width: 1180px) {
      bottom: 1vw;
    }

    @media (min-width: 1181px) and (max-width: 1500px) {
      bottom: -1vw;
    }

    @media (min-width: 1501px) and (max-width: 2300px) {
      bottom: -3vw;
    }

    @media (min-width: 2301px) {
      bottom: -4vw;
    }
  }

  @include desktop {
    padding-bottom: 0;
  }

  .home-solutions-services-content {
    padding: 60px 0 50px;
    background: #fff;
    position: relative;
    overflow: hidden;

    @include desktop {
      padding: 100px 0 210px;
    }

    .home-solutions-services-content-waves {
      position: absolute;
      top: 0;
      width: 100%;
      height: 50px;
      pointer-events: none;
      z-index: 1;
      display: flex;
      justify-content: space-between;

      @include desktop {
        height: 110px;
      }
    }

    .home-solutions-services-content-waves-left {
      max-width: 20%;
      transform: scale(-1, 1);
    }

    .home-solutions-services-content-waves-right {
      max-width: 40%;
    }

    p {
      color: #000;
      text-align: center;
      font-size: 22px;
      text-transform: uppercase;

      @include desktop {
        margin-bottom: 50px;
        font-size: 26px;
      }
    }

    ul {
      @include desktop {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        padding: 0 60px;
        row-gap: 30px;
      }

      li {
        a {
          display: flex;
          align-items: center;
          padding: 10px;
          font-size: 16px;
          text-decoration: none;
          color: #000;

          @include desktop {
            flex-direction: column;
            max-width: 240px;
            padding: 20px;
            box-sizing: border-box;
            margin: 0;
          }

          &:hover {
            color: var(--orange);
          }

          svg {
            width: 56px;
            height: 56px;
            margin-right: 20px;
            overflow: visible;

            @include desktop {
              margin-right: 0;
              margin-bottom: 10px;
            }
          }

          span {
            line-height: 120%;
            letter-spacing: -0.48px;

            @include desktop {
              text-align: center;
            }
          }
        }

        &:nth-child(7) {
          a {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}

.home-module-centres {
  img {
    max-width: 100%;
  }

  @include desktop {
    margin: 40px 0;
    padding: 0 60px;
  }
}

.home-events {
  position: relative;
  isolation: isolate;
  padding: 50px 10px 40px;

  &:before {
    display: none;
    content: '';
    position: absolute;
    width: 111.5px;
    height: 330.5px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background: url('../images/home-events-waves-left.png') no-repeat;
    background-size: contain;
    pointer-events: none;
  }

  &:after {
    display: none;
    content: '';
    position: absolute;
    width: 355px;
    height: 309px;
    top: 50%;
    transform: translateY(-70%);
    right: 0;
    background: url('../images/home-events-waves-right.png') no-repeat;
    background-size: contain;
    pointer-events: none;
  }

  @include desktop {
    &:before,
    &:after {
      display: block;
    }
  }

  .home-events-container {
    @include desktop {
      padding-right: 60px;
      padding-left: 60px;
    }
  }

  .home-events-title {
    margin-bottom: 40px;
    color: #000;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    text-transform: uppercase;

    @include desktop {
      font-size: 26px;
      margin-bottom: 55px;
    }
  }

  ul {
    position: relative;
    z-index: 1;
    @include desktop {
      display: flex;
      align-items: stretch;
    }

    li {
      background: #f4f4f4;
      border: 1px solid #999;
      box-shadow: 4px 4px 0 0 #999;

      &:hover {
        border-color: var(--orange);

        .home-event-title {
          color: var(--orange);
        }
      }

      @include desktop {
        flex: 1;
      }

      & + li {
        margin-top: 20px;

        @include desktop {
          margin-top: 0;
          margin-left: 20px;
        }
      }

      .home-event {
        display: grid;
        grid-template-areas:
          'title title'
          'tags icon';
        grid-template-columns: 1fr 46px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 20px;
        text-decoration: none;
        color: #000;

        .home-event-title {
          grid-area: title;
          margin-bottom: 40px;
          font-size: 18px;
          font-weight: 500;
          line-height: 120%;
        }

        .home-event-tags {
          grid-area: tags;
          align-self: end;
          margin-right: 10px;
          color: #999;
          font-size: 14px;
        }

        .home-event-icon {
          grid-area: icon;
          align-self: end;
          height: 46px;
        }
      }
    }
  }

  .home-events-all {
    text-align: center;
    margin-top: 20px;

    @include desktop {
      margin-top: 30px;
    }

    a {
      font-size: 16px;
      color: #000;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
