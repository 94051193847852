.select {
  position: relative;

  &.shown {
    .select-icon {
      transform: rotate(0);
    }

    .select-dropdown {
      display: block;
    }
  }

  .select-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 38px;
    padding: 0 10px;
    border: 1px solid rgba(255, 102, 0, 0.3);
    font-size: 12px;
    line-height: 130%;
    background: #eaeaea;
    cursor: pointer;
    isolation: isolate;
    color: var(--orange);
    box-sizing: border-box;

    @include desktop {
      font-size: 14px;
      padding: 0 25px;
    }
  }

  .select-icon {
    display: flex;
    width: 13px;
    height: 7px;
    transform: rotate(180deg);
  }

  .select-dropdown {
    position: absolute;
    top: 40px;
    box-sizing: border-box;
    display: none;
    width: 100%;
    padding: 10px 0;
    border: 1px solid rgba(255, 102, 0, 0.3);
    border-top: 0;
    z-index: 10;
    background: #eaeaea;

    li {
      padding: 5px 10px;
      font-size: 12px;
      line-height: 130%;
      cursor: pointer;

      @include desktop {
        font-size: 14px;
        padding: 5px 25px;
      }

      &.active {
        font-weight: 700;
      }
    }
  }
}
