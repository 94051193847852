.page {
  padding-top: 40px;
  background: linear-gradient(180deg, var(--orange) 0%, #fff 500px);

  &.not-found-page {
    background: linear-gradient(180deg, var(--orange) 0%, #ee813c 40%, #fff 100%);
  }

  @include desktop {
    position: relative;
    padding-top: 80px;
  }

  .page-category {
    position: relative;
    bottom: -1px;
    width: min-content;
    padding: 12px 36px;
    background: #fff;
    color: var(--orange);
    text-align: center;
    font-size: 22px;
    min-width: 165px;
    box-sizing: border-box;
    height: 50px;

    @include desktop {
      padding: 20px 65px 0;
      min-width: 245px;
    }
  }

  .page-header {
    padding: 40px 10px;
    background: #fff;

    @include desktop {
      padding: 40px 65px;
    }
  }

  .page-content {
    padding: 0 0 40px;
    background: #fff;
  }

  .page-breadcrumbs {
    padding: 10px 0;
    margin-bottom: 5px;

    @include desktop {
      padding: 15px 0;
    }

    .page-breadcrumbs-wrapper {
      position: relative;
      height: 28px;
    }

    .page-breadcrumbs-content {
      position: absolute;
      white-space: nowrap;
      width: 100%;
      overflow-x: auto;
      text-align: right;
      padding-bottom: 10px;

      & > * {
        display: inline-block;
        font-size: 12px;
        color: #999;
        text-decoration: none;

        &.active {
          text-decoration: underline;
          color: var(--orange);

          &:hover {
            text-decoration: none;
          }
        }

        @include desktop {
          font-size: 14px;
        }

        & + & {
          margin-left: 10px;
        }

        &::after {
          content: '/';
          margin-left: 4px;
        }

        &:last-child {
          &::after {
            content: '';
            margin-left: 0;
          }
        }
      }
    }
  }

  .page-title {
    display: flex;
    color: var(--orange);
    font-size: 26px;
    font-weight: 700;
    align-items: start;

    @include desktop {
      padding: 12px 0;
      font-size: 36px;
      align-items: center;
    }

    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;

      @include desktop {
        width: 50px;
        height: 50px;
        margin-right: 20px;
      }
    }
  }

  .page-description {
    margin-top: 20px;

    @include desktop {
      padding: 12px 0;
    }
  }

  .page-meta {
    margin-top: 10px;
    color: #999;
    font-size: 14px;
    line-height: 1;
    margin-left: 50px;

    @include desktop {
      margin-left: 70px;
      font-size: 18px;
    }
  }

  .page-content-container {
    padding: 40px 10px 20px;
    background: linear-gradient(180deg, #eaeaea 0%, #fff 500px);

    @include desktop {
      padding-top: 60px;
      padding-right: 65px;
      padding-left: 65px;
    }
  }

  .pills {
    margin-bottom: 40px;

    @include desktop {
      margin-bottom: 60px;
    }
  }

  .page-sub-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
    color: var(--orange);

    @include desktop {
      margin-bottom: 20px;
      font-size: 32px;
    }
  }

  .page-content-text {
    @include desktop {
      font-size: 16px;
    }

    .page-content-text-image-left,
    .page-content-text-image-right {
      min-width: 180px;
      max-width: 50%;

      @include desktop {
        max-width: 400px;
        margin-bottom: 10px;
      }
    }

    .page-content-text-image-left {
      float: left;
      margin-right: 10px;

      @include desktop {
        margin-right: 30px;
      }
    }

    .page-content-text-image-right {
      float: right;
      margin-left: 10px;

      @include desktop {
        margin-left: 30px;
      }
    }

    a {
      color: #000;
    }
  }

  .page-content-image {
    max-width: 1100px;
    margin: 30px auto;
    text-align: center;
  }

  .page-content-list {
    list-style-type: none;

    li {
      position: relative;
      padding-left: 16px;

      @include desktop {
        padding-left: 20px;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 6px;
        display: block;
        width: 6px;
        height: 6px;
        background: var(--orange);

        @include desktop {
          top: 7px;
          width: 8px;
          height: 8px;
        }
      }

      & + li {
        margin-top: 10px;
      }

      ul {
        margin-top: 10px;
        padding-left: 11px;

        li {
          list-style-type: '—';
          padding-left: 6px;

          &::marker {
            color: var(--orange);
          }

          &::before {
            content: '';
            display: none;
          }
        }
      }
    }
  }

  a {
    color: inherit;
  }
}

.page-top-waves {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 500px;
  z-index: 2;
  width: 100%;
  pointer-events: none;
  display: flex;
  justify-content: space-between;

  &:before,
  &:after {
    content: '';
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:before {
    width: 105px;
    height: 38px;
    background-image: url('../images/header-bg-left.png');

    @include desktop {
      width: 480px;
      height: 170px;
    }
  }

  &:after {
    width: 83px;
    height: 61px;
    background-image: url('../images/header-bg-right.png');

    @include desktop {
      width: 367px;
      height: 306px;
    }
  }
}
