$desktop_width: 1024px;

$mobile_media_query: 'only screen and (max-width: #{$desktop_width - 1})';
$desktop_media_query: 'only screen and (min-width: #{$desktop_width})';

@mixin mobile() {
  @media #{$mobile_media_query} {
    @content;
  }
}

@mixin desktop() {
  @media #{$desktop_media_query} {
    @content;
  }
}
