@import '_mixins.scss';

.footer {
  position: relative;
  overflow: hidden;
  padding: 32px 0;
  border-top: 10px solid var(--orange);
  background-image: url('../images/footer-background-mobile.jpg');
  background-repeat: repeat-y;
  background-size: cover;
  color: #fff;

  @include desktop() {
    padding: 70px 0 40px;
    border-width: 16px;
  }

  .container {
    padding: 0 20px;
  }

  .footer-waves {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 300px;
    pointer-events: none;
    z-index: 1;
    display: flex;
    align-items: flex-end;

    @include desktop() {
      left: 50%;
      width: 50%;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

.footer-wrapper {
  @include desktop() {
    padding-left: 65px;
    padding-right: 65px;
  }
}

.footer-collapsable-menu {
  margin-bottom: 40px;

  @include desktop() {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 2fr 2fr 2fr 1fr 1fr;
    padding-bottom: 40px;
    border-bottom: 1px solid #995b34;
    margin-bottom: 0;
  }

  & > li {
    border-bottom: 1px solid #995b34;

    @include desktop() {
      border-bottom: 0;

      &:last-child {
        justify-self: end;
      }
    }

    & > button,
    & > a {
      display: block;
      width: 100%;
      padding: 10px 0;
      font-weight: 700;
      color: #fff;

      @include desktop() {
        font-size: 16px;
      }
    }

    & > button {
      border: 0;
      border-radius: 0;
      background-color: transparent;
      background-image: url('../images/footer-collapsable-menu-arrow-down.svg');
      background-repeat: no-repeat;
      background-position-y: center;
      background-position-x: right;
      text-align: left;

      @include desktop() {
        background-image: none;
      }
    }

    & > a {
      text-decoration: none;
    }

    & > ul {
      display: none;
      padding: 0 0 10px;

      @include desktop() {
        display: block;
        padding: 0;
      }

      & > li {
        @include desktop() {
          padding: 1px 0;
        }

        & + li {
          margin-top: 5px;
        }

        a {
          color: #fff;
          font-weight: 400;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &.shown {
      & > button {
        background-image: url('../images/footer-collapsable-menu-arrow-up.svg');

        @include desktop() {
          background-image: none;
        }
      }

      & > ul {
        display: block;
      }
    }
  }
}

.footer-bottom {
  display: grid;
  grid-template:
    'contacts1 contacts1 contacts1 contacts1'
    'contacts2 contacts2 contacts2 contacts2'
    'social social social social'
    'conditions conditions conditions conditions'
    'conditions2 conditions2 conditions2 conditions2'
    'other other other other';

  @include desktop() {
    grid-template:
      'other contacts1 contacts2 social'
      'other contacts1 contacts2 social'
      'other contacts1 contacts2 conditions'
      'other contacts1 contacts2 conditions2';
    padding-top: 40px;
    grid-template-columns: 2fr 2fr 2fr 2fr;
  }
}

.footer-contacts {
  grid-area: contacts;

  @include desktop() {
    display: flex;
  }
}

.footer-contact {
  & + & {
    margin-top: 20px;

    @include desktop() {
      margin-top: 0;
      //margin-left: 47px;
    }
  }
}

.footer-contact1 {
  grid-area: contacts1;
}

.footer-contact2 {
  grid-area: contacts2;
}

.footer-contacts-title {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  color: var(--orange);
}

.footer-contacts-value {
  & + & {
    margin-top: 10px;
  }

  a {
    color: #fff;
  }
}

.footer-social {
  grid-area: social;
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;

  @include desktop() {
    margin-top: 0;
    margin-bottom: 0;
  }

  :first-child {
    margin-right: 10px;
  }

  button {
    width: 45px;
    height: 45px;
    border: 1ps solid var(--orange);
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &.footer-social-telegram {
      background-image: url('../images/footer-telegram.svg');
    }

    &.footer-social-vk {
      background-image: url('../images/footer-vk.svg');
      background-size: cover;
    }
  }
}

.footer-other {
  grid-area: other;
  display: flex;
  flex-direction: column;

  .footer-logo {
    width: 100px;
    height: 21px;
    margin-top: 18px;

    @include desktop() {
      margin-top: 0;
      width: 152px;
      height: 34px;
    }
  }

  .footer-tm {
    margin-top: 12px;
    font-size: 12px;

    @include desktop() {
      font-size: 16px;
    }
  }
}

.footer-conditions,
.footer-conditions2 {
  font-size: 12px;
  color: #fff;
  text-decoration: none;

  @include desktop() {
    align-self: end;
    font-size: 16px;
  }
}

.footer-conditions {
  grid-area: conditions;

  &:hover {
    text-decoration: underline;
  }
}

.footer-conditions2 {
  grid-area: conditions2;
}
