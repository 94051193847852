@import 'mixins';

.cookies-consent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #000;

  @include desktop {
    padding: 20px 60px;
    flex-direction: row;
    justify-content: space-between;
  }

  p {
    margin-bottom: 10px;
    color: #fff;
    font-size: 10px;

    @include desktop {
      margin-bottom: 0;
      font-size: 14px;
    }

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  button {
    width: 120px;
    height: 26px;
    background: #fff;
    border: 0;
  }
}
