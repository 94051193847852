.pills {
  @include desktop {
    padding: 0 120px;
  }

  .row {
    & + .row {
      margin-top: 10px;

      @include desktop {
        margin-top: 30px;
      }
    }

    @include desktop {
      display: flex;
      align-items: stretch;
      justify-content: center;
    }
  }

  .pills-item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 46px;
    padding: 5px 20px;
    background: #878787;
    border: 0;
    color: #fff;
    font-size: 16px;
    letter-spacing: -0.48px;
    box-shadow: 1px 3px 0 0 var(--orange);
    box-sizing: border-box;
    text-decoration: none;

    @include desktop {
      width: 50%;
      min-height: 65px;
      font-size: 20px;
      box-shadow: 3px 5px 0 0 var(--orange);
    }

    & + * {
      margin-top: 10px;

      @include desktop {
        margin-top: 0;
        margin-left: 30px;
      }
    }

    &.active {
      background: var(--orange);
    }
  }
}
