.table {
  .table-mobile {
    @include desktop {
      display: none;
    }

    .select {
      margin-bottom: 15px;

      .select-control {
        height: 26px;
        background: #fff;
      }

      .select-dropdown {
        top: 28px;
        padding: 5px 0;
        background: #fff;

        li {
          &.active {
            color: var(--orange);
            font-weight: 400;
          }
        }
      }
    }

    .table-mobile-content {
      border: 1px solid rgba(255, 102, 0, 0.3);
      padding: 10px;
    }

    .table-content-title {
      margin-bottom: 40px;
      color: #000;
      font-size: 18px;
      font-weight: 700;
      line-height: 100%;
    }

    .table-content-sub-title {
      margin-bottom: 10px;
      color: #000;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: -0.48px;
    }

    .table-content-text {
      color: #000;
      font-size: 12px;
      line-height: 130%;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .table-desktop {
    display: none;
    border: 1px solid rgba(255, 102, 0, 0.3);

    @include desktop {
      display: block;
    }

    .table-desktop-cell {
      flex: 1 1 auto;
      box-sizing: border-box;
      padding: 0 10px;
    }

    .table-desktop-header {
      display: flex;

      .table-desktop-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        height: 80px;
        background: var(--orange);
        color: #fff;

        & + * {
          border-left: 1px solid #feebac;
        }
      }
    }

    .table-desktop-row {
      display: flex;

      &:nth-child(2n + 2) {
        background: #fdc5a3;
      }

      .table-desktop-cell {
        font-size: 16px;
        min-height: 80px;
        padding: 12px;

        & + * {
          border-left: 1px solid #feebac;
        }
      }
    }

    .solutions-infrastructure-data-centers-stationary & {
      .table-desktop-cell {
        &:nth-child(1),
        &:nth-child(2) {
          flex: 0 0 25%;
        }

        &:nth-child(3) {
          flex: 0 0 50%;
        }
      }
    }

    .services-it-technical-support & {
      .table-desktop-cell {
        flex: 0 0 15%;

        &:nth-child(1) {
          flex: 0 0 25%;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          justify-content: center;
          text-align: center;
        }
      }

      .table-desktop-content {
        position: relative;
      }

      .table-desktop-row {
        &:nth-child(1) {
          .table-desktop-cell {
            &:nth-child(6) {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              width: 15%;
              background: #fff;
            }
          }
        }
      }

      .table-desktop-cell {
        display: flex;
        align-items: center;
      }
    }
  }
}
