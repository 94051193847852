@import '_mixins.scss';

.header {
  & > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;

    @include desktop() {
      height: 105px;
      justify-content: center;
    }
  }
}

.header-logo {
  display: block;
  width: 145px;
  height: 30px;
  position: relative;
  z-index: 1;

  @include desktop() {
    width: 226px;
    height: 37px;
  }
}

.header-burger {
  width: 35px;
  height: 22px;
  background: none;
  border: 0;
  padding: 0;
  position: relative;
  z-index: 1;

  @include desktop() {
    display: none;
  }
}

.header-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
  display: none;
  padding: 110px 10px 10px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(20px);

  &.shown {
    @include mobile() {
      display: block;
    }
  }

  @include desktop() {
    max-width: 1420px;
    margin: 0 auto;
    padding: 0 20px;
    z-index: 2;
    position: absolute;
    top: 0;
    height: 44px;
    display: grid;
    grid-template-areas: '. menu search';
    grid-template-columns: 0.5fr minmax(min-content, 870px) 0.5fr;
    align-items: end;
    justify-content: space-between;
    background: none;
    backdrop-filter: none;
  }

  @media (min-width: 1350px) {
    grid-template-columns: 0.5fr minmax(min-content, 870px) 0.5fr;
  }
}

.header-menu-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 35px;
  height: 35px;
  background: none;
  border: 0;
  padding: 0;

  @include desktop() {
    display: none;
  }
}

.header-search {
  display: flex;
  padding: 5px 7px;
  border-radius: 5px;
  background: #fff;
  box-sizing: border-box;
  margin-bottom: 31px;

  @include desktop() {
    margin-bottom: 0;
    grid-area: search;
    max-width: 9vw;
    margin-left: 20px;
    padding: 3px;
    justify-self: end;
  }

  @media (min-width: 1350px) {
    max-width: 220px;
  }

  input {
    display: block;
    flex: 1 1 auto;
    box-sizing: border-box;
    border: 1px solid #000;
    border-right: 0;
    border-radius: 3px 0 0 3px;
    min-width: 0;
  }

  button {
    display: flex;
    flex: 0 0 40px;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 0 3px 3px 0;
    border: 0;
    background: #000;
    color: #fff;

    @include desktop() {
      flex: 0 0 30px;
      height: 30px;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.header-menu-list {
  padding: 0 88px;

  @include desktop() {
    display: flex;
    grid-area: menu;
    padding: 0;
  }

  & > li {
    @include desktop() {
      position: relative;
      flex: 1 1 180px;

      & + li {
        & > a,
        & > button {
          border-left: 1px solid transparent;
        }
      }

      &:hover {
        & > button,
        & > a {
          color: var(--orange);
          background: #fff;
        }

        & > ul {
          display: block;
        }
      }
    }

    & > button,
    & > a {
      display: block;
      width: 100%;
      padding: 10px 0;
      font-size: 18px;
      font-weight: 700;
      color: #fff;
      background: none;
      letter-spacing: 0.5px;

      @include desktop() {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        padding: 0 20px;
        color: #fff;
        box-sizing: border-box;
        background: #878787;
        background-clip: padding-box;
        font-weight: 400;
      }

      /*      @media only screen and (width >= 1265px) {
        width: 180px;
      }*/
    }

    & > button {
      border: 0;
      border-radius: 0;
      background-image: url('../images/footer-collapsable-menu-arrow-down.svg');
      background-repeat: no-repeat;
      background-position-y: center;
      background-position-x: right;
      text-align: left;

      @include desktop() {
        background-image: none;
      }
    }

    & > a {
      text-decoration: none;
    }

    & > ul {
      display: none;
      padding: 9px 0 10px;

      @include desktop() {
        position: absolute;
        padding: 20px 0;
        background: #fff;
      }

      & > li {
        & + li {
          margin-top: 9px;
        }

        &:hover {
          a {
            color: var(--orange);
          }
        }

        a {
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          text-decoration: none;
          white-space: nowrap;

          @include desktop() {
            display: block;
            color: #000;
            padding: 0 20px;
          }
        }
      }
    }

    &.shown {
      @include mobile() {
        & > a,
        & > button {
          color: var(--orange);

          @include desktop() {
            color: #000;
          }
        }

        & > button {
          background-image: url('../images/footer-collapsable-menu-arrow-up.svg');

          @include desktop() {
            background-image: none;
          }
        }

        & > ul {
          display: block;
        }
      }
    }
  }
}
