@import 'npm:aos/dist/aos.css';
@import 'npm:swiper/swiper.scss';
@import 'npm:swiper/modules/effect-coverflow.scss';
@import 'reset';
@import 'header';
@import 'footer';
@import 'page';
@import 'home';
@import 'cookies';
@import 'table';
@import 'tabs';
@import 'pills';
@import 'select';

:root {
  --orange: #f60;
}

body {
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.3;
  color: #000;

  @include desktop {
    font-size: 16px;
  }
}

button,
a {
  cursor: pointer;
}

a {
  color: #000;

  &:hover {
    text-decoration: none;
  }
}

.no-decoration {
  text-decoration: none;
}

.mt-xs {
  margin-top: 5px;

  @include desktop {
    margin-top: 10px;
  }
}

.mt-s {
  margin-top: 10px;

  @include desktop {
    margin-top: 15px;
  }
}

.mt-m {
  margin-top: 20px;

  @include desktop {
    margin-top: 25px;
  }
}

.mt-l {
  margin-top: 30px;

  @include desktop {
    margin-top: 35px;
  }
}

.heading-3 {
  font-size: 18px;
  font-weight: 700;

  @include desktop {
    font-size: 22px;
  }
}

.heading-4 {
  font-size: 16px;
  font-weight: 700;

  @include desktop {
    font-size: 18px;
  }
}

.heading-5 {
  font-weight: 700;
}

.heading-underlined {
  display: inline-block;
  border-bottom: 1px solid var(--orange);
}

.orange {
  color: var(--orange);
}

.gray {
  color: #999;
}

.bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.nowrap {
  white-space: nowrap;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.mobile {
  display: block;

  @include desktop {
    display: none !important;
  }
}

.desktop {
  display: none;

  @include desktop {
    display: block;
  }
}

picture {
  display: block;

  img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
}

b {
  font-weight: 700;
}

.clearfix {
  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

.container {
  max-width: 1430px;
  margin: 0 auto;
  padding: 0 10px;

  @include desktop {
    padding: 0 20px;
  }
}

main {
  position: relative;
}

.overflowing-block {
  margin-left: -10px;
  margin-right: -10px;

  @include desktop {
    margin-left: -30px;
    margin-right: -30px;
  }
}

.gray-image-wrapper {
  padding: 30px 10px;
  margin-top: 30px;
  background: #d9d9d9;

  @include desktop {
    margin-left: 40px;
    margin-right: 40px;
    padding: 30px 30px;
  }

  .page-content-image {
    max-width: 100%;
  }
}

.sidcm-purposes {
  margin-bottom: 20px;

  @include desktop {
    display: flex;
    margin-bottom: 30px;
  }

  .sidcm-purposes-item {
    @include desktop {
      flex: 1 0 33%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;

      &:not(:last-child) {
        padding-right: 20px;
      }
    }

    picture {
      max-width: 180px;
      margin-bottom: 10px;
    }

    .sidcm-purposes-item-title {
      margin-bottom: 10px;
    }
  }
}

.sidcm-setec {
  padding: 20px 10px;
  margin-top: 40px;
  background: #eaeaea;

  @include desktop {
    padding: 20px 30px;
  }

  .sidcm-setec-title {
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: 700;
    color: var(--orange);
    text-align: center;
    text-transform: uppercase;
  }

  .sidcm-setec-list {
    .row {
      & + .row {
        margin-top: 20px;
      }

      @include desktop {
        display: flex;
        justify-content: center;
        align-items: stretch;
      }
    }

    .item {
      display: flex;
      align-items: start;

      @include desktop {
        flex-direction: column;
        flex: 0 1 33%;
        min-width: 0;
      }

      & + .item {
        margin-top: 20px;

        @include desktop {
          margin-top: 0;
          margin-left: 40px;
        }
      }

      span {
        display: block;
        width: 20px;
        text-align: center;
        margin-right: 10px;
        color: var(--orange);
        font-size: 30px;
        font-weight: 400;
        line-height: 1;

        @include desktop {
          margin-right: 0;
          margin-bottom: 10px;
          font-size: 64px;
          width: auto;
        }
      }
    }
  }

  picture {
    max-width: 180px;
    margin-bottom: 10px;
  }

  .sidcm-setec-bottom {
    @include desktop {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;

      picture {
        flex: 1 0 300px;
        max-width: 100%;
        margin-left: 40px;
      }
    }
  }
}

.corporate-communications {
  & + .corporate-communications {
    margin-top: 40px;
  }

  .page-content-text {
    margin-bottom: 20px;
  }

  .row {
    @include desktop {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 50px;
    }

    .item {
      display: flex;
      align-items: center;

      @include desktop {
        width: 380px;
        padding: 0 40px;
        box-sizing: border-box;
      }

      & + .item {
        margin-top: 20px;

        @include desktop {
          margin-top: 0;
        }
      }

      img {
        width: 50px;
        height: 50px;
        margin-right: 10px;

        @include desktop {
          width: 100px;
          height: 100px;
          margin-right: 15px;
        }
      }

      span {
        color: #000;
        font-size: 16px;
        line-height: 130%;

        @include desktop {
          font-size: 18px;
        }
      }
    }
  }

  .list {
    color: #000;
    font-size: 12px;
    line-height: 20px;
    margin-top: 20px;

    & + .list {
      margin-top: 5px;
    }

    @include desktop {
      font-size: 14px;
    }

    span,
    b {
      display: inline;
      margin-right: 10px;
      white-space: normal;
      word-break: break-word;
    }

    span {
      &:before {
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        background: var(--orange);
        margin-right: 5px;
        vertical-align: middle;
      }
    }
  }
}

.events {
  display: flex;
  flex-direction: column;
  align-items: center;

  .events-list {
    @include desktop {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px;
    }
  }

  .events-item {
    font-size: 14px;

    @include desktop {
      font-size: 16px;
    }

    & + .events-item {
      margin-top: 20px;

      @include desktop {
        margin-top: 0;
      }
    }
  }

  .events-item-header {
    display: flex;
    align-items: end;
    line-height: 1;
    padding-bottom: 5px;
    border-bottom: 1px solid #999;
  }

  .events-item-icon {
    height: 40px;
    width: auto;
  }

  .events-item-date {
    color: #999;
    margin-left: 5px;
  }

  .events-item-tags {
    margin-left: auto;
    color: #999;
    font-size: 10px;

    @include desktop {
      font-size: 12px;
    }
  }

  .events-item-title {
    margin-top: 10px;
    color: var(--orange);
    font-weight: 700;
    display: block;
    text-decoration: none;
  }

  .events-item-text {
    margin-top: 10px;

    a {
      color: #000;
    }
  }

  .events-see-more {
    margin-top: 20px;
    text-align: center;
    color: #000;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    @include desktop {
      margin-top: 40px;
    }
  }
}

.contacts {
  @include desktop {
    display: flex;
  }

  &-map {
    border: 1px solid #999;
  }

  .contacts-column {
    flex: 1 0 50%;
    box-sizing: border-box;

    @include desktop {
      & > *:not(.contacts-heading) {
        padding-left: 31px;
      }
    }

    .contacts-heading {
      position: relative;

      &:before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        vertical-align: middle;
        margin-right: 6px;

        @include desktop {
          width: 25px;
          height: 25px;
        }
      }
    }

    &:first-child {
      @include desktop {
        padding-right: 90px;
      }

      .contacts-heading {
        &:before {
          background-image: url('../images/icons/contacts-location.svg');
        }
      }
    }

    &:last-child {
      margin-top: 20px;

      @include desktop {
        margin-top: 0;
      }

      .contacts-heading {
        &:before {
          background-image: url('../images/icons/contacts-phone.svg');
        }
      }
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.reviews {
  &-select {
    @include desktop {
      display: flex;
      justify-content: right;
    }

    & > div {
      @include desktop {
        width: 290px;
      }
    }
  }

  .reviews-item {
    @include desktop {
      &:nth-child(2n) {
        background: #fff;
      }
    }

    & + .reviews-item {
      margin-top: 20px;

      @include desktop {
        margin-top: 40px;
      }
    }
  }

  .reviews-see-more {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color: #000;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    @include desktop {
      margin-top: 40px;
    }
  }
}

.gradient-card {
  padding: 15px 10px;
  border: 1px solid var(--orange);
  background: linear-gradient(180deg, #fff 0%, #f1cdb5 100%);

  @include desktop {
    padding: 30px;
  }
}

.careers-page {
  .gradient-card {
    margin: 20px 0;

    @include desktop {
      margin: 35px -30px;
    }
  }

  .careers-benefits {
    margin-top: 20px;

    @include desktop {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 35px;
      margin-top: 40px;
    }

    .careers-benefits-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include desktop {
        flex-direction: column;
        align-items: start;
      }

      img {
        width: 50px;
        height: 50px;
        margin-right: 10px;

        @include desktop {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }

      span {
        flex: 1;
      }

      & + .careers-benefits-item {
        margin-top: 20px;

        @include desktop {
          margin-top: 0;
        }
      }
    }
  }
}

.two-column-details {
  @include desktop {
    display: flex;
  }

  .two-column-details-column {
    &:first-child {
      @include desktop {
        flex: 0 0 230px;
      }
    }

    &:last-child {
      @include desktop {
        flex: 1;
      }
    }

    & + .two-column-details-column {
      margin-top: 20px;

      @include desktop {
        margin-top: 0;
      }
    }
  }
}

.customers {
  &-select {
    @include desktop {
      position: relative;
      display: flex;
      justify-content: right;
      z-index: 1;
    }

    & > div {
      @include desktop {
        width: 290px;
      }
    }
  }

  .customers-list {
    @include desktop {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 35px;
    }
  }

  .customers-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 150px;
    box-shadow: 4px 4px 0 0 #999999a6;
    border: 1px solid #fda774;
    position: relative;

    @include desktop {
      height: 200px;
    }

    &:hover {
      border-color: var(--orange);
      background: var(--orange);

      .customers-item-title,
      .customers-item-tags,
      .customers-footer {
        color: #fff;
      }

      .customers-projects {
        display: block;
      }
    }

    & + .customers-item {
      margin-top: 20px;

      @include desktop {
        margin-top: 0;
      }
    }

    .customers-item-tags {
      margin-bottom: 15px;
      font-size: 10px;

      @include desktop {
        font-size: 12px;
        margin-bottom: 30px;
      }
    }

    .customers-footer {
      text-decoration: underline;
    }

    .customers-projects {
      display: none;
      transform: translateY(100%);
      position: absolute;
      bottom: 0;
      left: -0.5px;
      right: -0.5px;
      background: var(--orange);
      color: #fff;
      padding: 0 10px 15px;
      box-sizing: border-box;
      border: 1px solid var(--orange);
      box-shadow: 4px 4px 0 0 #999999a6;
      z-index: 1;

      @include desktop {
        padding: 0 30px 30px;
      }

      li {
        &:before {
          background: #fff;
        }
      }

      a {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .customers-see-more {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color: #000;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    @include desktop {
      margin-top: 40px;
    }
  }
}

.search {
  display: flex;
  flex-direction: column;
  align-items: center;

  .search-list {
  }

  .search-item {
    font-size: 14px;
    padding: 20px 0;
    border-bottom: 1px solid #999;

    @include desktop {
      font-size: 16px;
      padding: 30px 0;
    }
  }

  .search-item-title {
    color: var(--orange);
  }

  .search-item-date {
    color: #999;
  }

  .search-see-more {
    margin-top: 20px;
    text-align: center;
    color: #000;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }

    @include desktop {
      margin-top: 40px;
    }
  }

  .search-sort {
    margin-top: 20px;
    text-align: center;
    padding: 0 50px;
    line-height: 150%;

    a {
      color: var(--orange);
      white-space: nowrap;
    }
  }
}

.page-header-search {
  display: flex;
  align-items: center;

  .page-header-search-input {
    flex: 1;
    height: 38px;
    flex-shrink: 0;
    display: block;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #000;

    @include desktop {
      flex: 0 0 288px;
      height: 40px;
    }
  }

  .page-header-search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background: none;
    border: none;
    padding: 10px;

    @include desktop {
      padding: 0 15px;
    }
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 10px;
  z-index: 100;
  padding: 10px;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background: none;
  border: 0;
}

.not-found {
  display: flex;
  flex-direction: column;
  min-height: 600px;
  color: #fff;

  @include desktop {
    flex-direction: row;
  }

  .not-found-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    @include desktop {
      flex: 1 0 50%;
    }

    &:first-child {
      align-items: center;
      font-size: 140px;
      box-sizing: border-box;
      justify-content: end;

      @include desktop {
        justify-content: center;
        align-items: end;
        padding-right: 57px;
        font-size: 200px;
      }
    }

    &:last-child {
      align-items: center;
      justify-content: start;

      @include desktop {
        justify-content: center;
        align-items: start;
      }

      & > * {
        &:first-child {
          font-size: 30px;
          margin-bottom: 30px;
          text-align: center;

          @include desktop {
            text-align: left;
            font-size: 34px;
          }
        }

        &:last-child {
          font-size: 32px;
          letter-spacing: 1.3px;
          text-align: center;

          @include desktop {
            text-align: left;
            font-size: 36px;
          }
        }
      }
    }
  }
}

.browser-update {
  display: none;
  position: fixed;
  inset: 0;
  background: rgba(#f60, 0.8);
  color: #fff;
  z-index: 100;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.shown {
    display: flex;
  }

  .browser-update-title {
    font-size: 22px;
    margin-bottom: 30px;
    font-weight: 700;
    text-align: center;

    @include desktop {
      margin-bottom: 40px;
      font-size: 26px;
    }
  }

  .browser-update-text {
    margin-bottom: 18px;
    letter-spacing: 1px;
    font-size: 16px;
    text-align: center;

    @include desktop {
      margin-bottom: 25px;
      font-size: 18px;
    }
  }

  .browser-update-icons {
    display: block;
    text-align: center;

    a {
      display: inline-block;
      padding: 12.5px;
      height: 50px;
      width: 50px;

      @include desktop {
        padding: 17.5px;
        height: 74px;
        width: 74px;
      }
    }
  }
}
