.tabs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  @include desktop {
    flex-direction: row;
    margin-bottom: 30px;
  }

  .tabs-item {
    padding: 0;
    border: 0;
    border-bottom: 1px solid #999;
    background: none;
    color: #999;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    text-align: center;

    & + .tabs-item {
      margin-top: 10px;

      @include desktop {
        margin-top: 0;
      }
    }

    @include desktop {
      padding: 0 10px 4px;
      font-size: 24px;
    }

    & + button {
      margin-top: 10px;

      @include desktop {
        margin-top: 0;
      }
    }

    &.active {
      border-bottom-color: var(--orange);
      color: #000;
    }
  }
}
